/** Remains: Cutouts video **/

import React from "react";
import { gsap } from "gsap"
import FullscreenVideo from "../../components/media/fullscreen-video"
import { preloadImages } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { showText, hideText, positionText, resetAnimationDelay } from "../../helpers"


let globalState, data;

class RemainsPage5 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[5];

    //refs
    this.bgVideoRef = this.fullscreenBg = null;
  }

  state = {
    textVisible: false,
  }


  componentDidMount = () => {

    setTimeout(() => {
      this.bgVideoRef.playVideo();
    }, globalState.transitionDuration);

    //position text
    positionText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);
    positionText(this.refs.text2Ref, "right", "top", globalState.textPosition.rightTop);


    //show text
    gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0 });
    gsap.to(this.refs.text2Ref, 0.15, { autoAlpha: 1.0 });

    //add base track
    globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1);

    if(globalState.transitionDirection === "prev") {
      globalState.baseAudioRef.updateTrack(2, globalState.cdnUrl + data.soundfile2, 60);      
    } else {
      //set volume for base track
      globalState.baseAudioRef.updateVolume(2, 60);

    }
    
    //Preload assets from next page
    //const preloadData = globalState.assets[2];
    preloadImages([
      globalState.cdnUrl + data.nextTitle,
    ], this.preloadCallback);

  }

  preloadCallback = () => {
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }



  componentWillUnmount() {
    this.bgVideoRef.pauseVideo();

    //Kill any tweens
    gsap.killTweensOf(this.refs.text1Ref);

    if(globalState.transitionDirection === "next") {
      //remove base track
      globalState.baseAudioRef.updateTrack(1, false);
    }

  }

  bgVideoEnded = () => {
    console.log("Bg Video ended");
      //this.bgVideoRef.seekVideo(30);
  }

  toggleTextDisplay = (event) => {

    //toggle text visibility
    this.setState({ textVisible: !this.state.textVisible });

    //reset the animation delay
    resetAnimationDelay();

    if (this.state.textVisible) {
      //show text
      showText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop, 0);
      showText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop, 0);

    } else {
      //hide text
      hideText(this.refs.text1Ref, "left");
      hideText(this.refs.text1Ref, "right");
    }
  }

  isTransitioning = () => {
    this.bgVideoRef.pauseVideo();
  }

  

  render() {

    return (

      <>

        <link rel="prefetch" href="/one-for-the-road" />

        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation="/one-for-the-road" prevLocation="/remains/3"
          isTransitioning={this.isTransitioning} />

        {data && (
        <div className="fullpage-wrapper">
          <SEO title="Pine Point - Remains" />


          <div className="text-wrapper">
          <div className="text text--left-top" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginLeft: "0", marginTop: "0" }} onClick={this.toggleTextDisplay} alt="Richard, then: That Guy Who was Always in Fights" />
            </div>
            <div className="text text--right-top" ref="text2Ref">
              <input type="image" src={globalState.cdnUrl + data.text2} style={{ marginRight: "2vw", marginTop: "3vh" }} onClick={this.toggleTextDisplay} alt="Richard, now: He Kept the Town Alive." />
            </div>
          </div>

          
          {data.bgVideo ?
            <FullscreenVideo file={globalState.videoUrl + data.bgVideo}
              poster={globalState.cdnUrl + data.bgVideoPoster} autoPlay={false} loop={true} muted={true} ended={this.bgVideoEnded} ref={div => this.bgVideoRef = div} fullscreenClass={'fullscreen-video'} globalState={globalState} /> : ''
          }


        </div>
        )}
      </>
    );
  }


};

export default RemainsPage5
